import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";

export default function Navigation() {
  const routes = [
    {
      path: "/",
      exact: true,
      element: <Home />,
      private: false,
    },
  ];
  return (
    <>
      <Routes>
        {routes.map((route, index) => (
          <>
            <Route key={index} path={route.path} element={route.element} />
          </>
        ))}
      </Routes>
    </>
  );
}
