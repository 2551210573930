import React from "react";
import Define from "../../assets/images/define.svg";
import Design from "../../assets/images/design.svg";
import Develop from "../../assets/images/develop.svg";
import Graph1 from "../../assets/images/Graph1.svg";
import Graph2 from "../../assets/images/Graph2.svg";
import Graph3 from "../../assets/images/Graph3.svg";
import distribute from "../../assets/images/distribute.svg";
import "../../styles/home.css";
import Section4 from "../../assets/images/Section4.png";
import Founder1 from "../../assets/images/Founder1.png";
import Founder2 from "../../assets/images/Founder2.png";
import Founder3 from "../../assets/images/Founder3.png";
import Footer from "../../components/Footer";

const Home = () => {
  const section2Cards = [
    {
      img: Define,
      name: "DEFINE",
      description:
        "We sharpen your idea(s) using our proprietary frameworks and market intelligence tools to articulate a clear, differentiated value proposition.",
    },
    {
      img: Design,
      name: "DESIGN",
      description:
        "Our designers test your concepts with future customers, build prototypes and refine them into a set of final designs.",
    },
    {
      img: Develop,
      name: "DEVELOP",
      description:
        "Our technical product team converts designs into a fully functional product. After a rigorous testing phase, we prepare your product for launch.",
    },
    {
      img: distribute,
      name: "DISTRIBUTE",
      description:
        "Our marketing team works with you to define a go-to-market strategy to identify channels and funnels to help you distribute your product.",
    },
  ];
  const section3Cards = [
    {
      name: "Go to market faster",
      desc: "Our processes are optimised to to get you to market in record time. Reducing time to market significantly increases your chances of success*",
      img: Graph1,
    },
    {
      name: "Build with an experienced team",
      desc: "Working with experienced founders, operators and technologists increases your chances of success by 45%.",
      img: Graph2,
    },
    {
      name: "Stay ahead of the AI curve",
      desc: "Our AI and ML experts ensure that your product uses the latest cutting-edge AI/ML technology to help you stay ahead of your competitors.",
      img: Graph3,
    },
  ];

  const section5Cards = [
    {
      name: "Spark",
      location: "Bangkok",
      desc: "Spark uses relationship science to bring people together over curated experiences.",
      category: "Dating",
      img: Founder1,
    },
    {
      name: "Wandery.ai",
      location: "Geneva",
      desc: "Wandery.ai helps streamline family travel planning with child-friendly recommendations.",
      category: "Travel",
      img: Founder2,
    },
    {
      name: "Attis Bot",
      location: "Delhi",
      desc: "Attis Bot is your personal AI librarian, giving you instant access to mental health resources. ",
      category: "Dating",
      img: Founder3,
    },
  ];
  return (
    <>
      <div className="home-page">
        <div className="home-section-1">
          <div className="home-section-text">
            <h1 className="title1">
              Great ideas don’t build
              <br /> themselves.
            </h1>
            <p className="subtitle1">
              Atrean works with future founders to convert ideas into disruptive
              technology ventures in large, growing markets.
            </p>
            <button className="button">Build with us</button>
          </div>
        </div>
        <div className="line"></div>
        <div className="home-section-2">
          <span className="tag">HOW IT WORKS</span>
          <h1 className="title2">
            Work with our in-house team of operators, designers and engineers to
            build ventures in record time.
          </h1>
          <div className="home-section-2-cards">
            {section2Cards.map((card, index) => {
              return (
                <div key={index} className="home-section-2-card">
                  <div className="home-section-2-card-img">
                    <img src={card.img} alt="" />
                  </div>
                  <div className="home-section-2-card-text">
                    <h1 className="card-title">{card.name}</h1>
                    <p className="card-subtitle">{card.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="line"></div>
        <div className="home-section-3">
          <span className="tag">WHY ATREAN</span>
          <div className="home-section-3-cards">
            {section3Cards.map((card, index) => {
              return (
                <div key={index} className="home-section-3-card">
                  <div className="home-section-3-card-text">
                    <h1 className="title2">{card.name}</h1>
                    <p className="subtitle1">{card.desc}</p>
                    <button className="secondary-button">Learn More</button>
                  </div>
                  <div className="home-section-3-card-img">
                    <img src={card.img} alt="" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="line"></div>

        <div className="home-section-4">
          <span className="tag">WHO WE ARE LOOKING FOR</span>
          <h1 className="title2">
            Ideal for deep business generalists with sharp consumer/ industry
            insights
          </h1>
          <div className="home-section-4-card">
            <div className="home-section-4-card-texts">
              <div className="home-section-4-text">
                <h1 className="title2">01</h1>
                <p className="subtitle2">Seasoned Industry Experts</p>
              </div>
              <div className="home-section-4-text">
                <h1 className="title2">02</h1>
                <p className="subtitle2">Researchers, Academics, Scientists</p>
              </div>
              <div className="home-section-4-text">
                <h1 className="title2">03</h1>
                <p className="subtitle2">
                  Consultants, Investment Bankers, Private Equity professionals
                </p>
              </div>
              <div className="home-section-4-text">
                <h1 className="title2">04</h1>
                <p className="subtitle2">Non-tech seasoned entrepreneurs </p>
              </div>
            </div>
            <img src={Section4} alt="" />
          </div>
        </div>
        <div className="line"></div>

        <div className="home-section-5">
          <span className="tag">SOME VENTURES WE’VE LAUNCHED</span>
          <h1 className="title2">
            Founders we’ve worked with have launched disruptive ventures across
            healthcare, travel and dating across the world.
          </h1>
          <div className="home-section-5-cards">
            {section5Cards.map((card, index) => {
              return (
                <div key={index} className="home-section-5-card">
                  <div className="home-section-5-card-img">
                    <img src={card.img} alt="" />
                  </div>
                  <div className="home-section-5-card-text">
                    <h1 className="subtitle1">
                      {card.name} / <span> {card.location}</span>
                    </h1>

                    <p className="subtitle3">{card.desc}</p>
                    <div className="category">
                      <div className="circle"></div>
                      <p>{card.category}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="line"></div>
        <div className="home-section-6">
          <span className="tag">BUILD with us</span>
          <h1 className="title1_2">
            Build the future with us for less than 5k USD/month.
          </h1>
          <div className="buttons">
            <button className="button">Build with us</button>
            <button className="secondary-button">Learn More </button>
          </div>
        </div>
        <div className="line"></div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
