import React from "react";
import "./footer.css";
const Footer = () => {
  return (
    <div className="footer">
      <div className="footerItems">
        <div className="footerItem">
          <p>Why Atrean</p>
        </div>
        <div className="footerItem">
          <p>How it works</p>
        </div>
        <div className="footerItem">
          <p>Ventures we’ve launched</p>
        </div>
        <div className="footerItem">
          <p>Who we are looking for</p>
        </div>
      </div>
      <div className="footer-text">
        <p>© 2024 Atrean. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
